import React, { useEffect, useState } from 'react'
import AddMembers from './addMembers';
import { Link } from 'react-router-dom';
import avatar from '../assets/imgs/theme/avatar2.jpg'
import '../App.css';
import axios from 'axios';

const EditableTableCell = ({ initialValue, onSave }) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(initialValue);

    const handleInputChange = (e) => {
        setValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setEditing(false);
            onSave(value);
        }
    };
    return (
        <td onClick={() => setEditing(true)}>
            {editing ? (
                <input
                    type="number"
                    value={value}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    autoFocus
                />
            ) : (
                <div>{value}</div>
            )}
        </td>
    );
};
const MemberList = () => {
    const url = process.env.REACT_APP_API_URL;
    const [open, setOpen] = useState(false);
    const [listMember, setListMember] = useState([]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const header = {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NywiaWF0IjoxNjk5MzQ1ODg3LCJleHAiOjE3MzA4ODE4ODd9.L-QgMeNW_hO4ngwl5kLkH6XFC3D8wcb-IZ_gfEEgkQE"
    };

    const handlePhoneSave = async (memberId, newValue) => {
        try {
            const response = await axios.put(
                `${url}/update-member/${memberId}`,
                { phone: newValue },
                { headers: header }
            );
            console.log('response', response)
            if (response.status === 201) {
                console.log(`Phone number updated successfully: ${memberId}`);
            }
        } catch (error) {
            console.error('Error updating phone number:', error);
        }
    };
    const handleData = async () => {
        const response = await axios.get(
            `${url}/all-members`,
            {
                headers: header
            }
        );
        const datas = response.data;
        setListMember(datas);
    };
    useEffect(() => {
        handleData();
    }, []);

    return (
        <>
            <main className="main-wrap">
                <section className="content-main">
                    <div className="content-header">
                        <h2 className="content-title">Members list</h2>
                        <div onClick={handleOpen}>
                            <Link className="btn btn-primary"><i className="material-icons md-add" style={{ verticalAlign: 'middle', fontSize: '20px' }}>add</i> Member</Link>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table custom-table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Phone</th>
                                            <th>Member</th>
                                            <th>Age</th>
                                            <th>Blood Group</th>
                                            <th>Gender</th>
                                            <th>Relation</th>
                                            <th>Address</th>
                                            <th>Pancard No</th>
                                            <th className="text-end"> Action </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listMember?.map((item, i) => (
                                                <tr key={i} className='align-center'>
                                                    <EditableTableCell
                                                        initialValue={item.phone}
                                                        onSave={(newValue) => handlePhoneSave(item.id, newValue)}
                                                    />
                                                    <td width="20%">
                                                        <Link className="itemside">
                                                            <div className="left">
                                                                {item.profileImg ? (
                                                                    <><img src={"http://5.161.78.72:3308/" + item.profileImg} className="img-sm img-avatar" /><br />{item.name}</>
                                                                ) : ""}

                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>{item.age}</td>
                                                    <td>{item.bloodGroup}</td>
                                                    <td>{item.gender}</td>
                                                    <td>{item.relation}</td>
                                                    <td>{item.address}</td>
                                                    <td>{item.panNo}</td>
                                                    <td className="text-end">
                                                        {/* <Link to={`/member-details/${item.id}`} className="btn btn-light rounded btn-sm font-s" style={{ marginRight: '5px' }}> <i style={{ verticalAlign: 'middle' }} className="material-icons md-visibility">visibility</i> </Link> */}
                                                        <Link to="" className="btn btn-light rounded btn-sm font-s"> <i style={{ verticalAlign: 'middle' }} className="material-icons md-block">block</i> </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <AddMembers open={open} handleClose={handleClose} />
        </>
    )
}

export default MemberList