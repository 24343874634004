import React, { useEffect, useState } from 'react'
import { TextField, Box, Typography, Button, Modal, Stack } from '@mui/material';
import '../App.css';
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const textFieldStyle = {
    fontSize: '16px',
    marginBottom : '10px'
};

const buttonStackStyle = {
    marginTop: '15px',
    justifyContent: 'flex-end'
};
const headerStyle = {
    borderBottom: '1px solid #046963',
    paddingBottom: '5px',
    marginBottom: '15px',
    fontSize: '18px'
};
const btnStyle = {
    backgroundColor: '#088178',
    color: 'white',
    '&:hover': { backgroundColor: '#046963' }
}
const AddMembers = ({ open, handleClose }) => {
    const url = process.env.REACT_APP_API_URL;
    const [mobile, setMobile] = useState('');
    const [error, setError] = useState('');
    const [validMobile, setValidMobile] = useState('mobile')

    const header = {
        Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NywiaWF0IjoxNjk5MzQ1ODg3LCJleHAiOjE3MzA4ODE4ODd9.L-QgMeNW_hO4ngwl5kLkH6XFC3D8wcb-IZ_gfEEgkQE"
    };

    const validateData = () => {
        let valid = false;
        if (mobile === "") {
            setValidMobile(mobile);
          valid = true;
        }
        return valid;
      };
    
    const handleData = async () => {
        await axios.get(
            `${url}/all-members`,
            { headers: header }
        );
    };
    const handleAddMember = async () => {
        try {
            if (!validateData()) {
                
                const response = await axios.post(
                    `${url}/add-member`,
                    {phone:mobile}
                )
                if (response.data?.success) {
                    handleData()
                    handleClose()
                }
            }
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred while creating a member.');
        }
    };
    
    useEffect(() => {
        handleData();
    }, []);
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Typography variant="h6" sx={headerStyle}>
                        Create New Member
                    </Typography>
                    <TextField fullWidth 
                        label="Phone"
                        variant="outlined"
                        type='number'
                        defaultValue="Small"
                        size="small"
                        sx={{ ...textFieldStyle }}
                        onChange={(e) => {
                            setMobile(e.target.value); 
                            setValidMobile(e.target.value);
                            setError('')
                        }}
                    />
                    {(error || validMobile === '') && (
                        <Typography variant="body1" sx={{ color: 'red', fontSize: '13px' }}>
                            {validMobile === '' ? 'Mobile number is required' : error}
                        </Typography>
                    )}
                    <Stack spacing={2} direction="row" sx={{ ...buttonStackStyle }}>
                        <Button variant="outlined" onClick={handleClose} sx={{ borderColor: '#088178', color: '#088178', '&:hover': { borderColor: '#046963' } }}>Cancel</Button>
                        <Button variant="contained" sx={{ ...btnStyle }} onClick={handleAddMember}>Add Member</Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    )
}

export default AddMembers