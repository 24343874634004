import axios from 'axios';
import React, { useEffect, useState } from 'react'
import avatar from '../assets/imgs/theme/avatar2.jpg'
import { Link } from 'react-router-dom';

const ViewRecognization = () => {

    const [listRecognization, setListRecognization] = useState([])

    const handleData = async () => {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-recognization-details`
        );
        const RecData = response.data.result;
        setListRecognization(RecData)
        console.log('RecData', RecData)
    };
    useEffect(() => {
        handleData();
    }, []);
    return (
        <>
            <main className="main-wrap">
                <section className="content-main">
                    <div className="content-header">
                        <h2 className="content-title">Recognization list</h2>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table custom-table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Name 2</th>
                                            <th>Upload 1</th>
                                            <th>Upload 2</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listRecognization?.map((item, i) => (
                                                <tr key={i} className='align-center'>
                                                    <td>{item.name}</td>
                                                    <td>
                                                    {item.type === 'education' ? (
                                                        <>
                                                            {item.class_standard} - {item.grade}
                                                        </>
                                                    ) : (
                                                        item.name2
                                                    )}
                                                    </td>
                                                    <td width="20%">
                                                        <Link className="itemside">
                                                            <div className="left">
                                                                <img
                                                                    className="img-sm img-avatar"
                                                                    alt="Userpic"
                                                                    src={item.upload1 ? ("http://5.161.78.72:3308/" + item.upload1) : avatar}
                                                                />
                                                            </div>
                                                            <i className="material-icons">download</i> 
                                                        </Link>
                                                    </td>
                                                    <td width="20%">
                                                        <Link className="itemside">
                                                            <div className="left">
                                                                <img
                                                                    className="img-sm img-avatar"
                                                                    alt="Userpic"
                                                                    src={item.upload2 ? ("http://5.161.78.72:3308/" + item.upload2) : avatar}
                                                                />
                                                            </div>
                                                            <i className="material-icons">download</i> 
                                                        </Link>
                                                    </td>
                                                    <td>{item.type}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default ViewRecognization
