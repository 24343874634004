import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import uploadTheme from '../assets/imgs/theme/upload.svg'
import '../App.css';
import axios from 'axios';

const ManageGallery = () => {
    const url = process.env.REACT_APP_API_URL;
    const fileInputRef = useRef(null);
    const [imageId, setImageId] = useState('');
    const [dataList, setDataList] = useState([]);
    const [type, setType] = useState('');
    const [galleryImage, setGalleryImage] = useState([]);
    const [editImage, setEditImage] = useState(null);

    const header = {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NywiaWF0IjoxNjk5MzQ1ODg3LCJleHAiOjE3MzA4ODE4ODd9.L-QgMeNW_hO4ngwl5kLkH6XFC3D8wcb-IZ_gfEEgkQE"
    };

    const handleFileChange = (e) => {
        setGalleryImage([...galleryImage, ...e.target.files]);
    };

    const handleImageUpload = async () => {
        const formData = new FormData();

        galleryImage.forEach((fileData, index) => {
            formData.append(`galleryImage`, fileData);
            formData.append(`type`, type)
        });

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/create-gallery-image`,
                formData,
                {
                    headers: header
                }
            );

            if (response.data?.success) {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                handleData()
            }
        } catch (error) {
            console.error('Error uploading images:', error);
        }
    };
    const handleDelete = async (id) => {
        await axios.delete(`${url}/delete-gallery-image/${id}`, { headers: header });
        handleData();
    };
    const handleEditImgChange = (e) => {
        const [file] = e.target.files;
        if (file && (file instanceof Blob || file instanceof File)) {
            setEditImage(file);
        }
    }
    const handleEditGallery = async () => {
        const formData = new FormData();
        formData.append(`galleryImage`, editImage);
        formData.append(`type`, type)
        try {
            const response = await axios.put(
                `${url}/update-gallery-image/${imageId}`,
                formData,
                { headers: header }
            );

            if (response.data?.success) {
                setEditImage(null)
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                handleData()
            }
        } catch (error) {
            console.error('Error uploading images:', error);
        }
    }
    const handleData = async () => {
        const response = await axios.get(
            `${url}/getall-gallery-image`,
            {
                headers: header
            }
        );
        const datas = response.data;
        setDataList(datas);
    };
    const handleSingleData = async (id) => {
        const response = await axios.get(
            `${url}/getsingle-gallery-image/${id}`,
            {
                headers: header
            }
        );
        setType(response.data?.result[0].type)
        setEditImage(response.data?.result[0].galleryImage)
        setImageId(id)
    };
    useEffect(() => {
        handleData();
    }, []);

    return (
        <>
            <main className="main-wrap">
                <section className="content-main">
                    <div className="row">
                        <div className="col-9">
                            <div className="content-header">
                                <h2 className="content-title">Add New Gallery</h2>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card mb-6">
                                <div className="card-header">
                                    <h4>Gallery</h4>
                                </div>
                                {editImage ? (
                                    <>
                                    <div className='row'>
                                        <div className="input-upload col-md-6">
                                        <img src={editImage instanceof Blob ? URL.createObjectURL(editImage) : ( ("http://5.161.78.72:3308/" + editImage) || uploadTheme)} alt="images" />
                                        </div>
                                    </div>
                                    <div className="card-body row">
                                        <div className="col-md-6">
                                            <input
                                                className="form-control"
                                                ref={fileInputRef}
                                                type="file"
                                                onChange={handleEditImgChange}
                                                multiple
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <select className="form-select form-select-sm"
                                                value={type}
                                                onChange={(e) => setType(e.target.value)}
                                            >
                                                <option value=''>select</option>
                                                <option value="gallery">Gallery</option>
                                                <option value="slider">Slider</option>
                                                <option value="audio">Audio</option>
                                                <option value="commity">Committe</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="card-body position-relative">
                                        <button className="btn btn-sm btn-brand position-absolute bottom-0 end-0 mb-15 mr-15 font-xs" onClick={handleEditGallery}> Upload </button>
                                    </div>
                                </>
                                ) : (
                                    <>
                                        <div className='row'>
                                            <div className="input-upload col-md-6">
                                                <img src={uploadTheme} alt="" />
                                            </div>
                                        </div>
                                        <div className="card-body row">
                                            <div className="col-md-6">
                                                <input
                                                    className="form-control"
                                                    ref={fileInputRef}
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    multiple
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <select className="form-select form-select-sm"
                                                    value={type}
                                                    onChange={(e) => setType(e.target.value)}
                                                >
                                                    <option selected>Select</option>
                                                    <option value="gallery">Gallery</option>
                                                    <option value="slider">Slider</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="card-body position-relative">
                                            <button className="btn btn-sm btn-brand position-absolute bottom-0 end-0 mb-15 mr-15 font-xs" onClick={handleImageUpload}> Upload </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card mb-6">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table custom-table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Gallery Image</th>
                                                    <th>Type</th>
                                                    <th className="text-end"> Action </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataList?.map((value, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <Link className="itemside">
                                                                <div className="left">
                                                                    <img className="img-sm img-avatar" src={"http://5.161.78.72:3308/" + value?.galleryImage} alt="Userpic" />
                                                                </div>
                                                            </Link>
                                                        </td>
                                                        <td>{value?.type}</td>
                                                        <td className="text-end" style={{ verticalAlign: 'middle' }}>
                                                            <button
                                                                className="btn btn-sm btn-brand rounded font-sm mt-15"
                                                                style={{ marginRight: '8px' }}
                                                                onClick={(e) => handleSingleData(value.galleryI_id)}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button className="btn btn-sm btn-brand rounded font-sm mt-15" > <span onClick={(e) => handleDelete(value.galleryI_id)} >Delete</span></button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main></>
    )
}

export default ManageGallery