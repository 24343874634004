import React from 'react'
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="content-login">
            <div className="center-wrapper">
                <div className="card mx-auto card-login">
                    <div className="card-body">
                        <h4 className="card-title mb-4">Sign in</h4>
                        <form>
                            <div className="mb-3">
                                <input className="form-control" placeholder="Username or email" type="text" />
                            </div>
                            <div className="mb-3">
                                <input className="form-control" placeholder="Password" type="password" />
                            </div>
                            <div className="mb-4">
                                <button type="submit" className="btn btn-primary w-100" onClick={() => navigate("/")}> Login </button>
                            </div>
                        </form>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

export default SignIn