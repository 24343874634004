import React from 'react'
import { Link } from 'react-router-dom';
import '../App.css';

const Header = () => {
    return (
        <>
            <main className="main-wrap">
                <header className="main-header navbar">
                    <div className="col-search"></div>
                    <Link to="/login" className="btn btn-sm btn-brand rounded font-sm mt-12">Log Out</Link>
                </header>
            </main>   
        </>
    )
}

export default Header