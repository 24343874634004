import React from 'react'
import '../App.css';

const Footer = () => {
    return (
        <main className="main-wrap">
            <footer className="main-footer font-xs">
                <div className="row pt-11">
                    <div className="col-sm-6">
                        <script>
                            document.write(new Date().getFullYear())
                        </script> ©, 2023
                    </div>
                    <div className="col-sm-6">
                        <div className="text-sm-end">
                            All rights reserved
                        </div>
                    </div>
                </div>
            </footer>
        </main>
    )
}

export default Footer