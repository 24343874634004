import { Outlet, Route, Routes } from 'react-router-dom';
import MemberList from './pages/memberList';
import MemberDetails from './pages/memberDetails';
import Footer from './components/footer';
import ManageGallery from './pages/manageGallery';
import SignIn from './pages/signIn';
import Sidebar from './components/sidebar';
import Header from './components/header';
import ViewRecognization from './pages/viewRecognization';
import ViewMatrimony from './pages/viewMatrimony';

function App() {
  return (
    <>
      <Routes >
        <Route
          path="/"
          element={
            <>
              <Sidebar />
              <Header />
              <Outlet/>
              <Footer />
            </>
          }>
          <Route exact path='' element={<MemberList />} />
          <Route path="/member-details/:id" element={<MemberDetails />} />
          <Route path="/manage-gallery" element={<ManageGallery />} />
          <Route path="/view-recognization" element={<ViewRecognization />} />
          <Route path="/view-matrimony" element={<ViewMatrimony />} />
        </Route>
        <Route path="/login" element={<SignIn />} />

      </Routes >
    </>
  );
}

export default App;
