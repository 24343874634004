import axios from 'axios';
import React, { useEffect, useState } from 'react'
import avatar from '../assets/imgs/theme/avatar2.jpg'
import { Link } from 'react-router-dom';

const ViewMatrimony = () => {
    const [listMatrimony, setListMatrimony] = useState([])

    const handleData = async () => {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get-matrimony-details`
        );
        const RecData = response.data.result;
        setListMatrimony(RecData)
        console.log('RecData', RecData)
    };

    const isImage = (filePath) => {
        const extension = filePath.split('.').pop().toLowerCase();
        return ['jpg', 'jpeg', 'png', 'gif'].includes(extension);
    };

    useEffect(() => {
        handleData();
    }, []);
    return (
        <>
            <main className="main-wrap">
                <section className="content-main">
                    <div className="content-header">
                        <h2 className="content-title">Matrimony list</h2>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table custom-table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Photo</th>
                                            <th>Biodata</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listMatrimony?.map((item, i) => (
                                            <tr key={i} className='align-center'>
                                                <td>{item.name}</td>
                                                <td className="col">
                                                    <Link className="itemside">
                                                        <div>
                                                            <img
                                                                className="img-sm img-avatar"
                                                                alt="Userpic"
                                                                src={item.photo ? ("http://5.161.78.72:3308/" + item.photo) : avatar}
                                                            />
                                                        </div>
                                                        <i className="material-icons" >download</i>
                                                    </Link>
                                                </td>
                                                <td className="col">
                                                    
                                                    {isImage(item.biodata) ? (
                                                        <Link className="itemside">
                                                            <div>
                                                                <img
                                                                    className="img-sm img-avatar"
                                                                    alt="Userpic"
                                                                    src={"http://5.161.78.72:3308/" + item.biodata}
                                                                />
                                                            </div>
                                                            <i className="material-icons">download</i>
                                                        </Link>
                                                    ) : (
                                                        <div>
                                                            <object
                                                                data={"http://5.161.78.72:3308/" + item.biodata}
                                                                type="application/pdf"
                                                                width="50%"
                                                                height="200px"
                                                            />
                                                            <i className="material-icons">download</i>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>

    )
}

export default ViewMatrimony
