import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios';
import '../App.css';
import avatar from '../assets/imgs/theme/brand-3.jpg'

const MemberDetails = () => {
    const url = process.env.REACT_APP_API_URL;
    const {id} = useParams();
    const [memberDetails, setMemberDetails] = useState();

    const header = {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NywiaWF0IjoxNjk5MzQ1ODg3LCJleHAiOjE3MzA4ODE4ODd9.L-QgMeNW_hO4ngwl5kLkH6XFC3D8wcb-IZ_gfEEgkQE"
    };

    const handleSingleMemberData = async () => {
        const response = await axios.get(
            `${url}/single-member-deatils/${id}`,
            {
                headers: header
            }
        );
        setMemberDetails(response.data?.result[0])
        // setImageId(id)
    };
    useEffect(() => {
        handleSingleMemberData();
    }, []);
    return (
        <>
            <main className="main-wrap">
                <section className="content-main">
                    <div className="content-header">
                        <Link to="/"><i className="material-icons md-arrow_back"></i> Go back </Link>
                    </div>
                    <div className="card mb-4">
                        <div className="card-header bg-primary" style={{ height: "150px" }}>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl col-lg flex-grow-0" style={{ flexBasis: "230px" }}>
                                    <div className="img-thumbnail shadow w-100 bg-white position-relative text-center" style={{ height: "190px", width: "200px", marginTop: "-120px" }}>
                                        <img src={memberDetails?.profileImg || avatar} className="center-xy img-fluid" alt="Logo Brand" />
                                    </div>
                                </div>
                                <div className="col-xl col-lg">
                                    <h3>{memberDetails?.name}</h3>
                                    <p>{memberDetails?.address}</p>
                                </div>

                            </div>
                            <hr className="my-4" />
                            <div className="row g-4">
                                <div className="col-md-12 col-lg-4 col-xl-2">
                                    <article className="box">
                                        <p className="mb-0 text-muted">Gender:{memberDetails?.gender}</p>
                                        <p className="mb-0 text-muted">Age:{memberDetails?.age}</p>
                                        <p className="mb-0 text-muted">Blood Group:{memberDetails?.bloodGroup}</p>
                                    </article>
                                </div>
                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                    <h6>Contacts</h6>
                                    <p>
                                        {memberDetails?.phone}
                                    </p>
                                </div>
                                <div className="col-sm-6 col-lg-4 col-xl-3">
                                    <h6>Pan Number</h6>
                                    <p>
                                       {memberDetails?.panNo}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main></>
    )
}

export default MemberDetails