import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/imgs/theme/logo.png'
import '../App.css';

const Sidebar = () => {

    return (
        <>
            <div className="screen-overlay"></div>
            <aside className="navbar-aside" id="offcanvas_aside">
                <div className="aside-top">
                    <Link className="brand-wrap">
                        <img className="logo" src={logo} alt="Evara Dashboard" />
                    </Link>
                    <div>
                        <button className="btn btn-icon btn-aside-minimize"> <i className="text-muted material-icons md-menu_open" >menu_open</i> </button>
                    </div>
                </div>
                <nav>
                    <div className="menu-aside">
                        <div className="menu-item">
                            <Link className="menu-link" to='/'> <i className="icon material-icons md-store" style={{ verticalAlign: 'middle', marginBottom: '3px' }}>store</i>
                                <span className="text">Manage Members</span>
                            </Link>
                        </div>
                        <div className="menu-item">
                            <Link className="menu-link" to="/manage-gallery"> <i className="icon material-icons md-add_box" style={{ verticalAlign: 'middle', marginBottom: '3px' }}>add_box</i>
                                <span className="text">Manage Gallery</span>
                            </Link>
                        </div>
                        <div className="menu-item">
                            <Link className="menu-link" to="/view-recognization"> <i className="icon material-icons md-add_box" style={{ verticalAlign: 'middle', marginBottom: '3px' }}>add_box</i>
                                <span className="text">Manage Recognization</span>
                            </Link>
                        </div>
                        <div className="menu-item">
                            <Link className="menu-link" to="/view-matrimony"> <i className="icon material-icons md-add_box" style={{ verticalAlign: 'middle', marginBottom: '3px' }}>add_box</i>
                                <span className="text">Manage Matrimony</span>
                            </Link>
                        </div>
                    </div>
                </nav>
            </aside>
        </>
    )
}

export default Sidebar